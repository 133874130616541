import payload_plugin_yhC11uKesJwDt7rFZ97clRtdc4l8XMnINmH5cOyTkEU from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.1.0_firebase-functions@6_c0d23727d5a9f34ad922e76258376ce0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4P6bnXnIyw4ZlJGtG3uMdYmzkOemlXV_WthpVf6tdqE from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5PzuHRdQ1p3W9IzAYyaX6dy8WqMCQymUP37JLVAZUm0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JvcRBS1I3pvJqEFPhStNVz2apdSa7d9lejck_EiPRRY from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_LoiefiAT4UdQECLz0uCzIns6kamOSxToTJCY5yvMd1w from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_i03BIwNUI0R1dfdixQnWkawG5lcxWqnCD9UsgCn8RO8 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EkYZsR_JVoN2wjytH4Ksj4hHQDkQHwG3258QkZ7jWM4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_p7Tfu6e_Tokupv1orWTzcPmnNWVqy9_D4sWf757dhJg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ldDM3FLkvfwNnAuH9kNIOsfw1PQoTSnGhHqYaNwR9DE from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/render/project/src/apps/refresh/.nuxt/components.plugin.mjs";
import prefetch_client_c6rr_i8PP94Wthmvv0xLmGOYQr91aY5xrONAoILCv8A from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@netlify+blobs@8.1.2_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_71d3814c6353a0bd10be9c0173cb3d8e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import i18n_A02er8Hr0Dwf9yx7u_SpeHVAbULbtflTbnhVHn6T7EI from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_Hs7ehR_pJgseCsS_eqWTCSEr9dtagCTSob6RMGepRW8 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+algolia@1.11.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.7.2___magica_8a272b4c0f503689006a15561faf01d9/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_LaZd0mPPURqtm1HtqmSBqM45hDMI6HKONbJTi3zWYhM from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.1.0_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_zMmZuwJhvitIhe1n7xtaqol2i_5J7_45FMuM1SpD_ZU from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.1.0_firebase-functions@6_c0d23727d5a9f34ad922e76258376ce0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M from "/opt/render/project/src/apps/refresh/.nuxt/vuefire-plugin.mjs";
import contentful_Usfel6V0Vns9VmnNZHhV_8k_ptW8RSPbHv_mAeZ889s from "/opt/render/project/src/apps/refresh/plugins/contentful.js";
import rollbar_lyhHRDrx0vOfatlSi6JTVSwQEijf1TZUlCVZo2hT59U from "/opt/render/project/src/apps/refresh/plugins/rollbar.ts";
import socialShare_GiW9qfs4iQiRvAwa10mX0puoImpW7XXd5ZRVV23tdWs from "/opt/render/project/src/apps/refresh/plugins/socialShare.js";
import toast_F_pfj7FgRLbXLAWiXjuGtvyN_yqw93f_EjHy5PiyXpc from "/opt/render/project/src/apps/refresh/plugins/toast.ts";
export default [
  payload_plugin_yhC11uKesJwDt7rFZ97clRtdc4l8XMnINmH5cOyTkEU,
  revive_payload_client_4P6bnXnIyw4ZlJGtG3uMdYmzkOemlXV_WthpVf6tdqE,
  unhead_5PzuHRdQ1p3W9IzAYyaX6dy8WqMCQymUP37JLVAZUm0,
  router_JvcRBS1I3pvJqEFPhStNVz2apdSa7d9lejck_EiPRRY,
  _0_siteConfig_LoiefiAT4UdQECLz0uCzIns6kamOSxToTJCY5yvMd1w,
  payload_client_i03BIwNUI0R1dfdixQnWkawG5lcxWqnCD9UsgCn8RO8,
  navigation_repaint_client_EkYZsR_JVoN2wjytH4Ksj4hHQDkQHwG3258QkZ7jWM4,
  check_outdated_build_client_p7Tfu6e_Tokupv1orWTzcPmnNWVqy9_D4sWf757dhJg,
  chunk_reload_client_ldDM3FLkvfwNnAuH9kNIOsfw1PQoTSnGhHqYaNwR9DE,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_c6rr_i8PP94Wthmvv0xLmGOYQr91aY5xrONAoILCv8A,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  i18n_A02er8Hr0Dwf9yx7u_SpeHVAbULbtflTbnhVHn6T7EI,
  plugin_Hs7ehR_pJgseCsS_eqWTCSEr9dtagCTSob6RMGepRW8,
  i18n_LaZd0mPPURqtm1HtqmSBqM45hDMI6HKONbJTi3zWYhM,
  plugin_client_zMmZuwJhvitIhe1n7xtaqol2i_5J7_45FMuM1SpD_ZU,
  vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M,
  contentful_Usfel6V0Vns9VmnNZHhV_8k_ptW8RSPbHv_mAeZ889s,
  rollbar_lyhHRDrx0vOfatlSi6JTVSwQEijf1TZUlCVZo2hT59U,
  socialShare_GiW9qfs4iQiRvAwa10mX0puoImpW7XXd5ZRVV23tdWs,
  toast_F_pfj7FgRLbXLAWiXjuGtvyN_yqw93f_EjHy5PiyXpc
]