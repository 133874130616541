<template>
  <div class="h-full w-full overflow-hidden xl:overflow-visible">
    <slot name="header" v-if="!props.hideHeader">
      <Container class="!px-0 sm:!px-4">
        <ProgressBar
          :current-step="activeStepIndex"
          :total-steps="stepsArray.length"
          :theme="theme"
        />
        <NavigationBar
          @next="nextStep"
          @prev="prevStep"
          :next-step="getStep(activeStepIndex + 1)"
          :prev-step="getStep(activeStepIndex - 1)"
          :theme="theme"
        />
      </Container>
    </slot>
    <div class="relative">
      <div v-for="(step, index) in stepsArray" :id="step + index" :key="index">
        <transition :name="transitionName">
          <div v-show="activeStepIndex === index">
            <slot :name="stepsArray[index]" :next-step="nextStep" :prev-step="prevStep" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import Container from '../atoms/Container.vue'
import NavigationBar from './NavigationBar.vue'
import ProgressBar from './ProgressBar.vue'
import type { ISteps } from '../../types'
import { THEMES } from '../../types/themes'

const props = withDefaults(
  defineProps<{
    steps: ISteps
    initStep: number
    theme?: THEMES
    hideHeader?: boolean
    scrollTop?: boolean
  }>(),
  {
    theme: THEMES.DEFAULT,
    hideHeader: false,
    scrollTop: true,
  },
)

const TRANSITIONS = {
  NEXT: 'step-next',
  PREV: 'step-prev',
}

const activeStepIndex = ref<number>(props.initStep || 0)
const transitionName = ref('step-next')

const stepsArray = computed(() => {
  return Object.entries(props.steps)
    .filter(([, value]) => value.visible === true)
    .map(el => el[0])
})

const getStep = (index: number) => {
  const key = stepsArray.value[index]
  const step = props.steps[key]
  return step
}

const nextStep = () => {
  transitionName.value = TRANSITIONS.NEXT
  if (activeStepIndex.value === stepsArray.value.length - 1) {
    return
  }
  activeStepIndex.value++
  scrollToTop()
}

const prevStep = () => {
  transitionName.value = TRANSITIONS.PREV
  if (activeStepIndex.value === 0) {
    return
  }
  activeStepIndex.value--
  scrollToTop()
}

const scrollToTop = () => {
  if (props.scrollTop) {
    setTimeout(() => window.scrollTo(0, 0), 800)
  }
}

watch(
  () => props.initStep,
  () => (activeStepIndex.value = props.initStep),
)
</script>

<style scoped>
.step-next-enter-active,
.step-next-leave-active,
.step-prev-enter-active,
.step-prev-leave-active {
  transition: transform 0.8s ease-in-out;
}
.step-next-enter-from {
  transform: translateX(100%);
}

.step-next-enter-active {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.step-next-leave-to {
  transform: translateX(-100%);
}

.step-prev-enter-from {
  transform: translateX(-100%);
}

.step-prev-enter-active {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.step-prev-leave-to {
  transform: translateX(100%);
}
</style>
